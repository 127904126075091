import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';

function Privacy() {
  return (
    <Layout>
      <SEO title='Privacy and Security' />
      <div className='grid-container'>
        <div className='grid-row'>
          <div className='grid-col-12'>
            <h4 className='text-center'>Privacy & Security Notice</h4>
            <p>
              The U. S. Army Medical Center of Excellence website is provided as a public
              service by the Office of the Army Surgeon General, Public Affairs, and the
              Network Enterprise Center, Fort Detrick, Md.
            </p>
            <p>
              Information presented on this service not identified as protected by
              copyright is considered public information and may be distributed or copied.
              Use of appropriate byline, photo, and image credits is requested.
            </p>
            <p>
              For site management, <Link to='/infocollect'>information is collected</Link>{' '}
              for statistical purposes. This U.S. Government computer system uses software
              programs to create summary statistics, which are used for such purposes as
              assessing what information is of most and least interest, determining
              technical design specifications, and identifying system performance or
              problem areas.
            </p>
            <p>
              For site security purposes and to ensure that this service remains available
              to all users, software programs are employed to monitor network traffic to
              identify unauthorized attempts to upload or change information, or otherwise
              cause damage.
            </p>
            <p>
              Except for authorized law enforcement investigations and national security
              purposes, no other attempts are made to identify individual users or their
              usage habits beyond DoD websites. Raw data logs are used for no other
              purposes and are scheduled for regular destruction in accordance with
              National Archives and Records Administration Guidelines. All data collection
              activities are in strict accordance with DoD Directive 5240.01.
            </p>
            <p>
              Web measurement and customization technologies (WMCT) may be used on this
              site to remember your online interactions, to conduct measurement and
              analysis of usage, or to customize your experience. The Department of
              Defense does not use the information associated with WMCT to track
              individual user activity on the Internet outside of Defense Department
              websites, nor does it share the data obtained through such technologies,
              without your explicit consent, with other departments or agencies. The
              Department of Defense does not keep a database of information obtained from
              the use of WMCT. General instructions for how you may opt out of some of the
              most commonly used WMCT is available at{' '}
              <a
                className='usa-link usa-link--external'
                href='http://www.usa.gov/optout_instructions.shtml'
                target='_blank'
              >
                http://www.usa.gov/optout_instructions.shtml
              </a>
              .
            </p>
            <p>
              Unauthorized attempts to upload information or change information on this
              site are strictly prohibited and may be punishable under the Computer Fraud
              and Abuse Act of 1987 and the National Information Infrastructure Protection
              Act (18 U.S.C. § 1030).
            </p>
            <p>
              If you have any questions or comments about the information presented here,
              forward them to{' '}
              <a
                className='usa-link'
                href='mailto:usarmy.detrick.93-sig-bde.mbx.nec-web-svcs@mail.mil'
              >
                usarmy.detrick.93-sig-bde.mbx.nec-web-svcs@mail.mil
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Privacy;
